import React, { useEffect, useState } from "react"
import { StringParam, useQueryParams, withDefault } from "use-query-params"
import fetchQuery from "../../utils/fetchQuery"
import PlanningHeader from "./planningHeader"
import PlanningYacht from "./planningYacht"
import moment from "moment"
import { navigate } from "gatsby"

const PlanningContent = () => {
    const currentYear = moment(new Date()).format("YYYY")
    const [errorMessage, setErrorMessage] = useState(null)
    const [yachts, setYachts] = useState([])
    const [query, setQuery] = useQueryParams({
        y: withDefault(StringParam, currentYear)
    })
    const { y: year } = query

    useEffect(() => {
        if (+year > 2037) navigate(`/planning?y=${currentYear}`)
    }, [year])

    const getYachts = async () => {
        await fetchQuery({
            method: "GET",
            endPoint: "configuration/Vessel/GetVesselList?status=true",
            successCallback: response => {
                setYachts(response.items)
            },
            failCallback: () => {
                setErrorMessage("Something went wrong")
            }
        })
    }

    useEffect(() => {
        getYachts()
    }, [])

    return (
        <div className="container-fluid container-xxl paddingy-planing position-relative">
            <PlanningHeader setQuery={setQuery} year={year} />
            <div className="col-12 d-flex flex-column align-items-center">
                {yachts?.map(y => (
                    <PlanningYacht selectedYear={year} key={y.id} yachtId={y.id} yachtName={y.vesselName} />
                ))}
                <div>{errorMessage}</div>
            </div>
        </div>
    )
}

export default PlanningContent
