import React, { useState } from "react"
import PropTypes from "prop-types"
import ArrowIcon from "./arrowIcon"

const DateSelect = ({ years, selectedYear, setQuery }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isHover, setIsHover] = useState(false)
    const [selected, setSelected] = useState(selectedYear)

    const selectClasses = `date-select ${isOpen ? "date-opened" : ""}`

    const onArrowLeave = () => {
        if (isOpen) return
        setIsHover(false)
    }

    const onSelectYear = year => {
        setSelected(year)
        setIsHover(false)
        setIsOpen(false)
        setQuery({ y: year })
    }

    return (
        <div
            onMouseLeave={onArrowLeave}
            onMouseEnter={() => setIsHover(true)}
            className={selectClasses}
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className="text-end selected-date">{selected}</div>
            <ArrowIcon
                color={`${isHover ? "#ffffff" : "#3d4998"}`}
                className={`arrow-select arrow-sm-color ${isHover && isOpen ? "arrow-select-hover" : ""}`}
            />

            {isOpen && (
                <>
                    <div className="date-underline d-none d-md-block"></div>
                    <div className="container container-dates pt-1">
                        <div className="date-options row">
                            {years.map(y => (
                                <div
                                    key={y}
                                    onClick={() => onSelectYear(y)}
                                    className="col-4 col-md-3 p-md-1 p-3 date-option text-center"
                                >
                                    {y}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

DateSelect.propTypes = {
    years: PropTypes.arrayOf(PropTypes.number).isRequired,
    selectedYear: PropTypes.string.isRequired
}

export default DateSelect
