import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

export const withAuth = Component => {
    let WrapperContainer = props => {
        const [authorized] = useState(typeof window !== "undefined" && window?.localStorage.getItem("token"))

        useEffect(() => {
            if (!authorized) {
                navigate("/")
            }
        }, [authorized])

        return <Component {...props} authorized={authorized} />
    }

    return WrapperContainer
}
