import React from "react"
import { Footer } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import PlanningContent from "../components/planning/planningContent"
import Seo from "../components/seo"
import { withAuth } from "../hoc/withAuth"

const Planning = withAuth(() => {
    return (
        <Layout>
            <Seo title="Planning" />
            <NavBar whiteNav bookNow={false} />
            <div className="top-gradient-container">
                <PlanningContent />
            </div>
            <Footer />
        </Layout>
    )
})

export default Planning
