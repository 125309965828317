import React from "react"
import DateSelect from "./dateSelect"
import PropTypes from "prop-types"

const PlanningHeader = ({ year, setQuery }) => {
    const years = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037]

    return (
        <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center position-relative">
                <h1 className="text-white planing-title">Planning</h1>
                <DateSelect setQuery={setQuery} selectedYear={year} years={years} />
            </div>
        </div>
    )
}

PlanningHeader.propTypes = {
    year: PropTypes.string.isRequired
}

export default PlanningHeader
