import React, { useCallback, useEffect, useMemo, useState } from "react"
import "react-dates/initialize"
import { DayPickerRangeController } from "react-dates"
import ArrowIcon from "./arrowIcon"
import formatPrice from "../../utils/priceFormatter"
import * as moment from "moment"
import { useWindowWidth } from "@react-hook/window-size"
import EditPen from "../../images/edit.svg"
import fetchQuery from "../../utils/fetchQuery"
import { Spinner } from "react-bootstrap"
import PropTypes from "prop-types"

const WINDOW_WIDTH = {
    LARGE: 1380,
    MEDIUM: 925,
    MOBILE: 768
}

const PlanningYacht = ({ yachtName, selectedYear, yachtId }) => {
    const [daysData, setDaysData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedDays, setSelectedDays] = useState([])
    const [daySize, setDaySize] = useState(60)

    const [date, setDate] = useState({
        startDate: undefined,
        endDate: undefined
    })
    const [isModal, setIsModal] = useState(false)
    const [focusedInput, setFocusedInput] = useState("startDate")
    const onlyWidth = useWindowWidth()

    const getData = useCallback(async () => {
        setIsLoading(true)

        const from = `${selectedYear}-01-01`
        const to = `${selectedYear}-12-31`

        await fetchQuery({
            method: "GET",
            endPoint: `configuration/Planning/GetAvailabilitiesAdmin?vesselId=${yachtId}&from=${from}&to=${to}`,
            successCallback: response => {
                setDaysData(response)
                setIsLoading(false)
                setIsModal(false)
                setDate({
                    startDate: undefined,
                    endDate: undefined
                })
                setSelectedDays([])
            },
            failCallback: () => {
                setIsLoading(false)
            }
        })
    }, [selectedYear, yachtId])

    const findSelectedDays = () => {
        const { startDate, endDate } = date
        let startDateIndex = undefined
        let endDateIndex = undefined

        if (endDate && daysData) {
            const endDateItem = daysData.find(d => moment(d.date).format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD"))
            endDateIndex = daysData.indexOf(endDateItem)
        }
        if (startDate && daysData) {
            const startDateItem = daysData.find(d => moment(d.date).format("YYYY-MM-DD") === startDate.format("YYYY-MM-DD"))
            startDateIndex = daysData.indexOf(startDateItem)
            const days = endDateIndex ? daysData.slice(startDateIndex, endDateIndex + 1) : [startDateItem]
            setSelectedDays(days)
        }
    }

    useEffect(() => {
        getData()
    }, [selectedYear])

    useEffect(() => {
        if (date.startDate || date.endDate) {
            findSelectedDays()
        }
    }, [date, daysData])

    useEffect(() => {
        if (onlyWidth >= 975) {
            setDaySize(60)
        }
        if (onlyWidth < 975 && onlyWidth >= 768) {
            setDaySize(55)
        }
        if (onlyWidth <= 490 && onlyWidth >= 390) {
            setDaySize(45)
        }
        if (onlyWidth <= 390) {
            setDaySize(37)
        }
    }, [onlyWidth])

    const isDayBlocked = useCallback(day => daysData.find(d => d.date === day.format("YYYY-MM-DDT00:00:00"))?.booked, [daysData])
    const renderDayContents = useCallback(
        day => {
            const dayInfo = daysData.find(d => d.date === day.format("YYYY-MM-DDT00:00:00"))

            const dayIsBlocked = dayInfo?.blocked

            const dayClassName = `day-wrapper ${dayIsBlocked ? "not-active" : ""}`
            return (
                <div className={dayClassName}>
                    <div className="day-date">{day.format("D")}</div>
                    {dayInfo?.price && (
                        <div className={`day-price ${dayInfo?.blocked ? "day-price-blocked" : ""}`}>
                            {formatPrice(dayInfo?.price)}€
                        </div>
                    )}
                </div>
            )
        },
        [daysData]
    )

    const dayPickerConfig = useMemo(
        () => ({
            numberOfMonths: onlyWidth > WINDOW_WIDTH.LARGE ? 3 : onlyWidth < WINDOW_WIDTH.MEDIUM ? 1 : 2,
            daySize,
            maxDate: moment(`${selectedYear}-12-31`),
            minDate: moment(`${selectedYear}-01-01`),
            initialVisibleMonth: () => (selectedYear === moment().format("YYYY") ? moment() : moment(`${selectedYear}-01-01`)),
            hideKeyboardShortcutsPanel: true,
            startDate: date.startDate,
            endDate: date.endDate,
            focusedInput,
            onFocusChange: focusedInput => setFocusedInput(focusedInput || "startDate"),
            onDatesChange: ({ startDate, endDate }) => setDate({ startDate, endDate }),
            isDayBlocked,
            renderDayContents,
            renderMonthElement: ({ month }) => {
                return <div>{moment(month).format("MMM")}</div>
            },
            navPrev: (
                <div disabled={isLoading} className="navigation-button-wrapper button-prev">
                    <ArrowIcon color="#3d4998" className="arrow-prev" />
                </div>
            ),
            navNext: (
                <div disabled={isLoading} className="navigation-button-wrapper button-next">
                    <ArrowIcon color="#3d4998" className="arrow-next" />
                </div>
            )
        }),
        [date.startDate, date.endDate, daysData, onlyWidth, selectedYear, daySize]
    )

    const EditButton = () => {
        return (
            <button onClick={() => setIsModal(true)} className="mobile-edit-calendar d-flex justify-content-center align-items-center">
                <img src={EditPen} alt="Edit Pen" />
            </button>
        )
    }

    const EditModal = () => {
        const allDayIsBlocked = !selectedDays.some(d => !d?.blocked)
        const [priceField, setPriceField] = useState("")
        const [comment, setComment] = useState(selectedDays[0]?.comment ?? "")
        const [blocked, setBlocked] = useState(allDayIsBlocked)
        const [error, setError] = useState(null)
        const [isLoadingEdit, setIsLoadingEdit] = useState(false)

        const formatDate = date => {
            return date?.format("MMMM Do")
        }

        const onSubmit = useCallback(async () => {
            setIsLoadingEdit(true)
            setError(null)

            const from = moment(selectedDays[0]?.date).format("YYYY-MM-DD")
            const to = moment(selectedDays[selectedDays.length - 1]?.date)
                .add(1, "days")
                .format("YYYY-MM-DD")
            const price = blocked ? undefined : priceField.length ? +priceField : undefined

            await fetchQuery({
                method: "POST",
                endPoint: "configuration/Planning/EditAvailability",
                body: {
                    vesselId: yachtId,
                    from,
                    to,
                    price,
                    blocked,
                    comment: blocked ? comment : undefined
                },
                toJSON: false,
                successCallback: response => {
                    if (response.ok) {
                        const copyDaysInfo = [...daysData]

                        selectedDays.map(d => {
                            const indexInMain = copyDaysInfo.findIndex(c => c.date === d.date)
                            copyDaysInfo[indexInMain] = !d?.booked
                                ? {
                                      ...copyDaysInfo[indexInMain],
                                      price,
                                      blocked,
                                      comment: blocked ? comment : ""
                                  }
                                : { ...copyDaysInfo[indexInMain] }
                        })

                        setDaysData(copyDaysInfo)
                        setDate({
                            startDate: undefined,
                            endDate: undefined
                        })
                        setSelectedDays([])
                        setIsModal(false)
                    } else {
                        setError("Something went wrong. Please try again")
                        setIsLoadingEdit(false)
                    }
                },
                failCallback: () => {
                    setError("Something went wrong. Please try again")
                    setIsLoadingEdit(false)
                }
            })
        }, [priceField, selectedDays, blocked, comment])

        return (
            <div className="modal d-block modal-edit">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content container">
                        <div className="modal-header">Edit</div>
                        <div className="modal-body">
                            <p className="my-0">
                                Edit price for{" "}
                                <b>
                                    {formatDate(date.startDate)} {date.endDate ? `- ${formatDate(date.endDate)}` : ""}
                                </b>
                            </p>
                            <div className="form-check pt-3 pt-md-4 my-0 pb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="boocked"
                                    checked={blocked}
                                    onChange={e => setBlocked(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="blocked">
                                    Blocked
                                </label>
                            </div>

                            {blocked && (
                                <textarea
                                    value={comment}
                                    onChange={e => setComment(e.target.value)}
                                    type="text"
                                    className="form-control text-area-comment mt-2"
                                    id="comment"
                                    placeholder="Comment"
                                    rows={5}
                                    style={{ height: "auto" }}
                                />
                            )}

                            {!blocked && (
                                <div className="form-floating">
                                    <input
                                        value={!priceField ? undefined : priceField}
                                        onChange={e => setPriceField(e.target.value)}
                                        type="number"
                                        className="form-control mt-2"
                                        id="price"
                                        placeholder="Phone Number"
                                    />
                                    <label htmlFor="price">New Price</label>
                                </div>
                            )}

                            {error && <div className="form-error-message">{error}</div>}
                        </div>
                        <div className="modal-footer pb-3">
                            <button
                                disabled={isLoadingEdit}
                                type="button"
                                className="btn btn-cancel"
                                onClick={() => setIsModal(false)}
                            >
                                cancel
                            </button>
                            <button disabled={isLoadingEdit} onClick={onSubmit} className="button fill dark">
                                <span>{isLoadingEdit ? "saving..." : "save"}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return !isLoading ? (
        <>
            <div className="row">
                <div className="col-12 container paddingy-yacht yacht-col position-relative">
                    <div className="pb-4">
                        <h3 className="my-0">{yachtName}</h3>
                    </div>
                    <div className="d-flex justify-content-center">
                        {onlyWidth > WINDOW_WIDTH.LARGE && <div className="header-bg-calendar"></div>}
                        <div className="position-relative" style={{ zIndex: 3 }}>
                            <DayPickerRangeController {...dayPickerConfig} />
                            {onlyWidth > WINDOW_WIDTH.LARGE && <div className="calendar-footer"></div>}
                            {date.startDate && <EditButton />}
                        </div>
                    </div>
                </div>
            </div>
            {isModal && <EditModal />}
        </>
    ) : (
        <Spinner className="mt-5" animation="border" />
    )
}

PlanningYacht.propsTypes = {
    yachtName: PropTypes.string.isRequired,
    selectedYear: PropTypes.string.isRequired,
    yachtId: PropTypes.string.isRequired
}

export default PlanningYacht
